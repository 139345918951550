import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, ReplaySubject, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {GlobalService} from './global.service';
import {Message} from 'primeng//api';

@Injectable({
  providedIn: 'root',
})

export class HttpInterceptorService implements HttpInterceptor {
  private _pendingRequests = 0;
  private _pendingRequestsStatus: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  
  get pendingRequestsStatus$(): Observable<boolean> {
    console.log('get...', this._pendingRequestsStatus);
    return this._pendingRequestsStatus.asObservable();
  }
  
  get pendingRequests(): number {
    return this._pendingRequests;
  }
  
  constructor(
    private msgService: MessageService,
    private location: Location,
    private globalService: GlobalService
  ) {
  }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._pendingRequests++;
    
    if (this._pendingRequests > 0) {
      this.globalService.isLoadingVar.next(true);
    }
    
    if (1 === this._pendingRequests) {
      this._pendingRequestsStatus.next(true);
    }
    
    return next.handle(request).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse && event.status === 200) {
          return event;
        }
        return this.handleData(event);
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleData(error);
      }),
      finalize(() => {
        this._pendingRequests--;
        if (0 === this._pendingRequests) {
          this._pendingRequestsStatus.next(false);
          this.globalService.isLoadingVar.next(false);
        }
      })
    );
  }
  
  private handleData(event: HttpResponse<any> | HttpErrorResponse): Observable<any> {
    switch (event.status) {
      case 401: // 未登录状态码
        const referrerUrl = encodeURI(this.location.path());
        if (!referrerUrl.startsWith('login')) {
          console.log(referrerUrl);
          window.location.href = `#login?referrerUrl=${referrerUrl}`;
        }
        break;
      case 404:
        window.location.href = 'assets/pages/404.html';
        break;
      case 500:
      case 600:
        let message: Message = {
          key: 'top-right',
          severity: 'error',
          summary: 'server error : ' + event.status,
          sticky: true
        };
        
        if (event instanceof HttpErrorResponse) {
          let error = event.error;
          
          let data = {} as any;
          
          let messageLength = error.length;
          if (messageLength > 100) {
            data.error = error.substring(0, 100) + '......';
            data.errorLong = error;
          } else {
            data.error = error;
          }
          
          message.data = data;
          
        } else {
          message.detail = 'code：' + event.status
        }
        
        this.msgService.add(message);
        
        break;
      case 503:
        window.location.href = '#error503';
        break;
    }
    
    return of(event);
  }
}


