import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public isLoadingVar: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  public userInfo: any;
  
  public userInfoChange: BehaviorSubject<any> = new BehaviorSubject({});
}
