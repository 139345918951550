<div class="layout-container" [ngClass]="containerClass">
  <app-topbar></app-topbar>
  <app-sidebar></app-sidebar>
  <div class="layout-content-wrapper">
    <div class="layout-content">
      <div class="layout-content-inner">
        <!--<my-breadcrumb></my-breadcrumb>-->
        <div style="min-height: 12px;" class="global-progress-bar">
          <p-progressBar mode="indeterminate" [style]="{height: '15px'}" *ngIf="loading"></p-progressBar>
        </div>
        <div [@routeAnimations]="animationRoute(outletInfo)">
          <router-outlet #outletInfo="outlet"></router-outlet>
        </div>
        <div class="global-progress-bar">
          <p-progressBar mode="indeterminate" [style]="{height: '15px',position:'absolute',bottom:'0',width : '89%'}" *ngIf="loading"></p-progressBar>
        </div>
      </div>
    </div>
  </div>
  <app-config></app-config>
</div>

