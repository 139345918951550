import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

const textHttpOptions = {
  headers: new HttpHeaders({'Content-Type': 'text'})
};

@Injectable({
  providedIn: 'root',
})
export class HttpclientService {
  
  constructor(private httpClient: HttpClient) {
  }
  
  
  upload(url: string, files: FileList, fileKey: string, path?: string): Observable<{}> {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(fileKey, files[i]);
    }
    
    if (path) {
      formData.append('path', path);
    }
    
    return this.httpClient.post(url, formData);
  }
  
  upload2(url: string, files: File[], fileKey: string, json?: string): Observable<{}> {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(fileKey, files[i]);
    }
    
    if (json) {
      formData.append('json', json);
    }
    
    return this.httpClient.post(url, formData);
  }
  
  /**
   * get请求
   * @param {string} url
   * @return {Observable<Object>}
   */
  public get(url: string): Observable<any> {
    return this.httpClient.get(url);
  }
  
  public getString(url: string) {
    return this.httpClient.get(url, {responseType: 'text'})
      .toPromise()
      .then(res => {
        return res;
      });
  }
  
  /**
   * post请求
   * @param {string} url
   * @param body
   * @return {Observable<Object>}
   */
  public post(url: string, body: any | null): Observable<any> {
    return this.httpClient.post(url, body, httpOptions);
  }
  
  public postDownload(url: string, body: any | null, httpOptions): Observable<any> {
    return this.httpClient.post(url, body, httpOptions);
  }
  
  /**
   * put请求
   * @param {string} url
   * @param body
   * @return {Observable<Object>}
   */
  public put(url: string, body: any | null): Observable<any> {
    return this.httpClient.put(url, body, httpOptions);
  }
  
  /**
   * delete请求
   * @param {string} url
   * @return {Observable<Object>}
   */
  public delete(url: string): Observable<any> {
    return this.httpClient.delete(url);
  }
  
  public download(url: string, body: any | null) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    
    return this.httpClient.post(url, body, {
      responseType: 'blob',
      observe: 'response',
      headers: headers
    });
  }
}
