import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppLayoutComponent} from './layout/app.layout.component';

const routes: Routes = [
  {
    path: '', component: AppLayoutComponent,
    children: [
      {
        path: 'test/image_capture_test',
        loadChildren: () => import('./pages/test/image_capture_test/image_capture_test.module').then(m => m.ImageCaptureTestModule),
        data: {animationName: 'test/image_capture_test'}
      },
      {
        path: 'setting/user_setting',
        loadChildren: () => import('./pages/user/setting/user_setting/user_setting.module').then(m => m.UserSettingModule),
        data: {animationName: 'setting/user_setting'}
      },
      {
        path: 'setting/my_demand_user',
        loadChildren: () => import('./pages/user/setting/my_demand_user/my_demand_user.module').then(m => m.MyDemandUserModule),
        data: {animationName: 'setting/my_demand_user'}
      },
      {
        path: 'order/demand',
        loadChildren: () => import('./pages/order_goods/demand_order_goods/demand_order_goods.module').then(m => m.DemandOrderGoodsModule),
        data: {animationName: 'order/demand'}
      },
      {
        path: 'order/demand/my_init',
        loadChildren: () => import('./pages/order_goods/my_init_demand_order/my_init_demand_order.module').then(m => m.MyInitDemandOrderModule),
        data: {animationName: 'order/demand/my_init'}
      },
      {
        path: 'order/demand/my_reply',
        loadChildren: () => import('./pages/order_goods/my_reply_demand_order/my_reply_demand_order.module').then(m => m.MyReplyDemandOrderModule),
        data: {animationName: 'order/demand/my_init'}
      },
      {
        path: 'order/demand/my_publish',
        loadChildren: () => import('./pages/order_goods/my_publish_demand_order/my_publish_demand_order.module').then(m => m.MyPublishDemandOrderModule),
        data: {animationName: 'order/demand/my_publish'}
      },
      {
        path: 'order/demand/my_publish_summary',
        loadChildren: () => import('./pages/order_goods/my_publish_summary/my_publish_summary.module').then(m => m.MyPublishSummaryModule),
        data: {animationName: 'order/demand/my_publish_summary'}
      },
      {
        path: 'order/demand/my_publish_summary/not_reply',
        loadChildren: () => import('./pages/order_goods/my_publish_summary/my_publish_summary.module').then(m => m.MyPublishSummaryModule),
        data: {animationName: 'order/demand/my_publish_summary'}
      },
      {
        path: 'order/demand/my_all_publish',
        loadChildren: () => import('./pages/order_goods/my_all_publish_demand_order/my_all_publish_demand_order.module').then(m => m.MyAllPublishDemandOrderModule),
        data: {animationName: 'order/demand/my_all_publish'}
      },
      {
        path: 'order/demand/my_demand_invoice',
        loadChildren: () => import('./pages/order_goods/demand_invoice/demand_invoice.module').then(m => m.DemandInvoiceModule),
        data: {animationName: 'order/demand/my_demand_invoice'}
      },
      {
        path: 'order/demand/publish',
        loadChildren: () => import('./pages/supply_goods/reply_demand_order/reply_demand_order.module').then(m => m.ReplyDemandOrderModule),
        data: {animationName: 'order/demand/publish'}
      },
      {
        path: 'order/demand/reply',
        loadChildren: () => import('./pages/supply_goods/not_confirm_demand_order/not_confirm_demand_order.module').then(m => m.NotConfirmDemandOrderModule),
        data: {animationName: 'order/demand/reply'}
      },
      {
        path: 'order/demand/invoice',
        loadChildren: () => import('./pages/supply_goods/confirm_demand_order/confirm_demand_order.module').then(m => m.ConfirmDemandOrderModule),
        data: {animationName: 'order/demand/invoice'}
      },
      {
        path: 'order/demand/all',
        loadChildren: () => import('./pages/supply_goods/supply_user_demand_order/supply_user_demand_order.module').then(m => m.SupplyUserDemandOrderModule),
        data: {animationName: 'order/demand/all'}
      },
      {
        path: 'order/demand_invoice',
        loadChildren: () => import('./pages/supply_goods/supply_user_demand_order/supply_user_demand_order.module').then(m => m.SupplyUserDemandOrderModule),
        data: {animationName: 'order/demand_invoice'}
      },
      {
        path: 'order/supply_invoice/new',
        loadChildren: () => import('./pages/supply_invoice/create_supply_invoice/create_supply_invoice.module').then(m => m.CreateSupplyInvoiceModule),
        data: {animationName: 'order/supply_invoice/new'}
      },
      {
        path: 'order/supply_invoice/edit/:id',
        loadChildren: () => import('./pages/supply_invoice/edit_supply_invoice/edit_supply_invoice.module').then(m => m.EditSupplyInvoiceModule),
        data: {animationName: 'order/supply_invoice/edit/:id'}
      },
      {
        path: 'order/supply_invoice/my_list',
        loadChildren: () => import('./pages/supply_invoice/my_all_invoice/my_all_invoice.module').then(m => m.MyAllInvoiceModule),
        data: {animationName: 'order/supply_invoice/my_list'}
      },
      {
        path: 'order/supply_invoice/distribution_invoice',
        loadChildren: () => import('./pages/supply_invoice/distribution_invoice/distribution_invoice.module').then(m => m.DistributionInvoiceModule),
        data: {animationName: 'order/supply_invoice/distribution_invoice'}
      }
    ]
  },
  {path: 'login', loadChildren: () => import('./pages/user/login/login.module').then(m => m.LoginModule)},
  {path: 'register', loadChildren: () => import('./pages/user/register/register.module').then(m => m.RegisterModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
