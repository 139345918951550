import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  constructor(private primengConfig: PrimeNGConfig, private translateService: TranslateService) {
  }
  
  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.translateService.setDefaultLang('zh-CH');
    this.translateService.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
  }
}

(window as any)['global'] = window;
